/** =================== Poppins =================== **/

/** Poppins-Bold **/
@font-face {
  font-family: 'Poppins-Bold';
  src: url('../../fonts/poppins/Poppins-Bold.ttf') format('truetype');
}

/** Poppins-ExtraBold **/
@font-face {
  font-family: 'Poppins-ExtraBold';
  src: url('../../fonts/poppins/Poppins-ExtraBold.ttf') format('truetype');
}

/** Poppins-Medium **/
@font-face {
  font-family: 'Poppins-Medium';
  src: url('../../fonts/poppins/Poppins-Medium.ttf') format('truetype');
}

/** Poppins-Regular **/
@font-face {
  font-family: 'Poppins-Regular';
  src: url('../../fonts/poppins/Poppins-Regular.ttf') format('truetype');
}

/** Poppins-SemiBold **/
@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../../fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
}