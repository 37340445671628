::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

img {
  object-fit: cover;
}

::-webkit-scrollbar-thumb {
  background: #D4D4D4;
  border-radius: 3px;
}

@media only screen and (max-width: 500px) {
  .point-of-view-section-image {
    width: 300px;
  }
}

@media only screen and (max-width: 345px) {
  .point-of-view-section-image {
    width: 250px;
  }
}

@media only screen and (max-width: 290px) {
  .point-of-view-section-image {
    width: 200px;
  }
}

@media only screen and (max-width: 247px) {
  .point-of-view-section-image {
    width: 150px;
  }
}

.faq-section-heading {
  font-Size: "36px";
  color: #fff;
  font-Weight: 700;
}

.faq-questions {
  font-size: 22px !important;
  font-weight: 700 !important;
  color: #000000 !important;
  margin-Bottom: 16px !important;
}

.faq-answers {
  font-size: 18px !important;
  line-height: 27px !important;
  font-Weight: 400 !important;
  color: #00000080 !important;
}

.faq-main-heading {
  font-size: 36px !important;
  color: #00ADB5 !important;
  font-Weight: 700 !important;
  margin-Top: 52px !important;
}

.text-color-primary {
  color: #00ADB5;
}

.font-family-bold {
  font-family: 'poppins-bold';
}

.img-fluid {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-playlist {
  min-width: 80px;
  text-align: start;
}

.about-playlist::after {
  content: ":";
  position: relative;
  left: 12px;

}

.need-help-btn:hover {
  background-color: transparent !important;
}

.accept-continue-btn {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #00ADB5 !important;
  padding: 8px 12px !important;
  border: 0.2px solid #00000066 !important;
  background-color: #fff !important;
  height: auto !important;
  border-radius: 5px !important;
  font-family: Poppins-Regular !important;
}

.dashboard-name-input {
  max-width: 380px;
  width: 100% !important;

}

.dashboard-cardno-input {
  max-width: 250px;
  width: 100% !important;
}

.dashboard-Exp-input {
  max-width: 120px;
  width: 100% !important;
}

.terms-main-heading {
  font-size: 36px !important;
  font-weight: 700 !important;
  color: #00ADB5;
  text-align: center;
  margin-bottom: 20px !important;
}

.terms-link-text {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #00ADB5 !important;
  line-height: 24px !important;
}

.terms-about-text {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #00000080 !important;
  line-height: 24px !important;
  margin-bottom: 20px !important;
}

.terms-sub-heading {
  font-size: 22px !important;
  font-weight: 700 !important;
  color: #00ADB5 !important;
  margin-bottom: 16px !important;
  padding-top: 52px;
}

.terms-sub-heading-2 {
  font-size: 22px !important;
  font-weight: 700 !important;
  color: #00ADB5 !important;
  /* margin-bottom: 16px !important; */
  padding-top: 52px;
}

.terms-sub-title {
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #000000 !important;
  margin-bottom: 8px !important;
}

.policy-list {
  list-style: disc;
}

.p-0 {
  padding: 0px !important;
}


/* terms and privacy pagfe responsive 
=========================================================== */
@media(max-width:580px) {
  .terms-main-heading {
    font-size: 25px !important;
  }

  .terms-link-text {
    font-size: 13px !important;
  }

  .terms-about-text {
    font-size: 13px !important;
  }

  .terms-sub-heading {
    font-size: 17px !important;
  }

  .terms-sub-titl {
    font-size: 15px !important;
  }
}



.custom-srcollbar-blue {
  ::-webkit-scrollbar {
    width: 16px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px #F0F0F080;  */
    background-color: #F0F0F080;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #00ADB5;
    border-radius: 10px;
    padding: 0 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #00ADB5;
  }
}

.terms-list-dot {
  display: flex;
  gap: 7px;
  margin-left: 10px !important;

}

/* responsive css  */
@media(max-width:768px) {
  .faq-main-heading {
    font-size: 25px !important;
  }

  .faq-answers {
    font-size: 15px !important;
  }

  .faq-questions {
    font-size: 18px !important;
  }

  .faq-section-heading {
    font-size: 25px !important;
  }
}

.navigate-icon .swiper-button-next:after {
  font-size: 25px;
  font-weight: 600;
  color: #00ADB5;
}

.navigate-icon .swiper-button-prev:after {
  font-size: 25px;
  font-weight: 600;
  color: #00ADB5;
}

.navigate-icon .swiper-pagination-bullet-active {
  background-color: #00ADB5;
}

.navigate-icon .swiper-button-next {
  top: 35%;
  right: 4%;
}

.navigate-icon .swiper-button-prev {
  top: 35%;
  left: 4%;
}

.text-color-black2 {
  color: #00000080;
}

.cancel-btn-link {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
}




/* responsive css 
=============================================  */

@media only screen and (min-width:1010px) and (max-width:1200px) {
  .maxwidth-300 {
    max-width: 300px !important;
  }

}

@media(max-width:1010px) {
  .flex-direction-column-md {
    flex-direction: column !important;
  }

  .flex-direction-row-md {
    flex-direction: row !important;
  }

  .display-none-sm {
    DISPLAY: NONE !important;
  }

  .tab-change-mobileview {
    display: flex !important;
    align-items: center !important;
    max-width: none !important;
    border: 0 !important;
  }
}

@media(max-width:760px) {
  .flex-direction-column-sm {
    flex-direction: column !important;
  }

  .flex-direction-row-sm {
    flex-direction: row !important;
  }

  .align-items-start-sm {
    align-items: start !important;
  }

  .margin-0 {
    margin: 0 !important;
  }

  .width-fit-content {
    width: fit-content !important;
  }

  .padding-top-100-sm {
    padding-top: 100px;
  }
}

@media(max-width:855px) {
  .flex-direction-column-xsm {
    flex-direction: column !important;
  }

  .flex-direction-row-xsm {
    flex-direction: row !important;
  }

  .w-100-sm {
    width: 100% !important;
  }

  .h-auto-sm {
    height: auto !important;
  }

  .border-bottom-sm {
    border-right: 0 !important;
    border-bottom: 1px solid #00000080;
  }

  .text-align-center-sm {
    text-align: center;
  }

  .justify-content-center-sm {
    justify-content: center;
  }

  .font-23-sm {
    font-size: 23px !important;
  }

  .margin-0-xsm {
    margin: 0 !important;
  }

  .padding-o-sm {
    padding: 0 !important;
  }

  .margin-top-16-sm {
    margin-top: 16px !important;
  }

  .padding-bottom-20vh-sm {
    padding-bottom: 20vh;
  }

  .height-auto-sm {
    height: auto !important;
  }
}

@media(max-width:470px) {
  .flex-direction-column-small {
    flex-direction: column;
  }

  .margin-auto-sm {
    margin: auto;
  }
}

.searchPadding .css-nyra02-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px;
}

.thumbupstyle {
  width: 15px !important;
  height: 15px !important;
}

.MuiPickersCalendarHeader-label {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 255px;
}

.MuiDateCalendar-root {
  background-color: #00ADB5;
  color: white;
  width: 312px !important;
  border-radius: 8px;
}

.MuiSvgIcon-fontSizeMedium.MuiPickersCalendarHeader-switchViewIcon {
  display: none;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiPickersArrowSwitcher-button {
  position: absolute !important;
  left: 52px !important;
  color: rgb(255 255 255 / 40%)
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart.MuiPickersArrowSwitcher-button {
  right: 46px !important;
  color: rgb(255 255 255 / 40%)
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPickersPopper-paper {
  border-radius: 12px;
}

.MuiDayCalendar-weekDayLabel.MuiTypography-caption.MuiTypography-root {
  color: rgb(255 255 255 / 40%) !important;
  font-size: 12px !important;
  font-family: 'Poppins-Regular';
}

.MuiPickersCalendarHeader-label {
  color: #FFFFFF;
  font-size: 14px;
  margin: 0 !important;
  font-family: 'Poppins-Regular';
}

.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
  color: #FFFFFF;
  font-family: 'Poppins-Regular';
  font-size: 12px;
}

.MuiPickersDay-dayWithMargin.MuiPickersDay-dayOutsideMonth {
  color: rgb(255 255 255 / 50%);
  font-family: 'Poppins-Regular';
  font-size: 12px;
}

.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-today {
  color: #FF8800;
  font-family: 'Poppins-Regular';
  font-size: 12px;
  border: 0
}

button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
  color: #FF8800;
  font-family: 'Poppins-Regular';
  font-size: 12px;
  border: 0;
  background-color: rgba(204, 117, 18, 0.485)
}

button.MuiButtonBase-root.MuiPickersDay-root.MuiDateRangePickerDay-day.MuiDateRangePickerDay-notSelectedDate.MuiDateRangePickerDay-dayInsideRangeInterval {
  color: white;
}

.MuiDateRangeCalendar-monthContainer {
  background-color: #00ADB5;
  color: white;
  min-width: 245px;
  border-radius: 8px;
}

.MuiDateRangePickerDay-notSelectedDate.MuiDateRangePickerDay-dayOutsideRangeInterval {
  color: #FFFFFF;
  font-family: 'Poppins-Regular';
  font-size: 12px;
}

.MuiPickersDay-dayOutsideMonth.MuiDateRangePickerDay-dayOutsideRangeInterval {
  color: rgb(255 255 255 / 12%);
  font-family: 'Poppins-Regular';
  font-size: 12px
}


button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-today {
  color: #FF8800;
  font-family: 'Poppins-Regular';
  font-size: 12px;
  border: 0
}

.MuiPickersCalendarHeader-root {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.MuiPickersPopper-paper {
  margin-top: 5px;
}

.sort-by-select .MuiInputBase-input:after {
  content: url('../images/svg/sort-arrow.svg');
  position: absolute;
  margin-left: 5px;
  margin-top: 1px;
}

.sort-by-select .MuiInputBase-input:before {
  content: 'Sort By ';

}

.point-of-view-section-image {
  max-width: 441px;
  max-height: 354px;
}

iframe.abc {
  height: "200px";
}

/* .privacy-table table, td, th {
  border: 0.5px solid #00000080 !important;
  text-align: center;
} 



.privacy-table th {
  background: #EFEFEF;
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  padding: 12px 15px;
}

.privacy-table td{
  color: #00000080;
  font-size: 12px;
  font-weight: 400;
  padding: 18px 25px;
}

.privacy-table .table {
  border-collapse: collapse;
  width: 100%;
} */

.terms-about-text-italic {
  font-size: 16px !important;
  font-style: italic;
  font-weight: 600 !important;
  margin-bottom: 8px !important;
}

.StripeElement {
  border-bottom: 1px solid #00000080;
  width: 100%;
  padding: 10px;
  padding-left: 0px;
}

.StripeElement--invalid {
  border-bottom: 1px solid red;
  width: 100%;
  padding: 10px;
  padding-left: 0px;
}

.table-container {
  box-shadow: none !important;
  border: 1px solid #00000040;
}

.spotify-white path {
  fill: #ffffff !important;
}

.swiper-slide {
  padding: 15px;
  margin: 0 !important;
  box-sizing: border-box;
}

.swiper-wrapper {
  overflow: hidden !important;
}

.cookie-buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.cookie-content {
  margin: 0px 0px 20px 0px !important;
  flex: 1 !important;
  font-size: 12px;
  font-family: 'Poppins-Regular';
  text-align: justify;
}