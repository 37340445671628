.Toastify__toast {
  font-family: 'Poppins-Bold';
  background-color: #0BD67E !important;
  max-height: 50px;
  min-height: 50px;
  border-radius: 5px !important;
  font-size: 14px;
}

.Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter {
  display: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

.css-1md30qv-MuiFormHelperText-root {
  padding-left: 25px;
  position: relative;
  margin-top: 7px !important;
  color: #4588c0 !important;
}

/* .css-1md30qv-MuiFormHelperText-root:before {
  content: url('/src/assets/images/pngs/lock.png');
  position: absolute;
  left: 0;
} */
.css-h67psh {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.Toastify__toast-container--bottom-right {
  bottom: 3rem !important;
  left: 8rem !important;
  width: 261px
}

.Toastify__toast-body {
  height: 27px;
}

.Toastify__close-button {
  align-self: center;
  height: 17px;
  width: 13px;
  opacity: 1;
}